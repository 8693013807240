/* eslint-disable */
export const generateRegularPolygonMockData = ({
    minSides = 3, // Minimum number of sides (inclusive)
    maxSides = 10, // Maximum number of sides (inclusive)
    minX = 0, // Minimum X position (inclusive)
    maxX = window.innerWidth, // Maximum X position (inclusive)
    minY = 0, // Minimum Y position (inclusive)
    maxY = window.innerHeight, // Maximum Y position (inclusive)
    minStrokeWidth = 1, // Minimum stroke width (inclusive)
    maxStrokeWidth = 5, // Maximum stroke width (inclusive)
    minWidth = 100, // Minimum width (inclusive)
    maxWidth = 300, // Maximum width (inclusive)
} = {}) => {
    // Generate random number of sides between min and max (inclusive)
    const sides = Math.floor(Math.random() * (maxSides - minSides + 1)) + minSides;
    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    // Generate random fill color
    const fill = generateRandomColor();
    // Generate random position within specified ranges
    const x = Math.floor(Math.random() * (maxX - minX + 1)) + minX;
    const y = Math.floor(Math.random() * (maxY - minY + 1)) + minY;

    // Choose random fill color from the provided array
    // const fill = fillColors[Math.floor(Math.random() * fillColors.length)];

    // Choose random stroke color from the provided array (or use default black)
    const stroke = generateRandomColor();

    // Generate random height and width within specified ranges
    //   const height = Math.floor(Math.random() * (maxHeight - minHeight + 1)) + minHeight;
    const width = Math.floor(Math.random() * (maxWidth - minWidth + 1)) + minWidth;
    // Generate random stroke width
    const strokeWidth = Math.floor(Math.random() * (maxStrokeWidth - minStrokeWidth + 1)) + minStrokeWidth;

    return {
        id: crypto.randomUUID(),
        sides,
        x,
        y,
        fill,
        stroke,
        strokeWidth,
        height: width,
        width,
        isSelected: false,
    };
};

export const generateFabricRandomPolygon = (minWidth = 0, maxWidth = 300, minHeight = 0, maxHeight= 100) => {
    // Generate random width and height within specified ranges
    const width = Math.floor(Math.random() * (maxWidth - minWidth + 1)) + minWidth;
    const height = Math.floor(Math.random() * (maxHeight - minHeight + 1)) + minHeight;

    // Rest of the function logic remains the same...

    const numVertices = Math.floor(Math.random() * 6) + 3;

    const points = [];
    for (let i = 0; i < numVertices; i++) {
        points.push({
            x: Math.floor(Math.random() * width),
            y: Math.floor(Math.random() * height),
        });
    }
    const id = crypto.randomUUID();
    return {
        id,
        points,
        options: {
            id,
            left: Math.floor(Math.random() * (2*window.innerWidth - width)), // Adjust for random position within canvas boundaries
            top: Math.floor(Math.random() * (2*window.innerHeight - height)),
            fill: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.5)`,
            opacity: 0.5,
            hasControls: false,
            hasRotatingPoint: false, 
        },
    };
};
