import { createSlice} from '@reduxjs/toolkit';


const initialState = {canvasInstance: null};

const canvasSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    canvasSet: (state, action) => {
      state.canvasInstance = action.payload.canvas;
    },
  },
});

export const { canvasSet } = canvasSlice.actions;


export const selectCanvas = (state) => 
  state.canvas.canvasInstance


export default canvasSlice.reducer;