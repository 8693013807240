import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Row, List, Typography, Button, Select, InputNumber, Popconfirm, Card, Table, Spin, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import { notificationError, requestError } from '../../../helpers/notification';
import { NeedsContext } from '../../../contexts/needs/NeedsContext';
import { PlanningNeedsContext } from '../../../contexts/needs/PlanningNeedsContext';
import { getArrayInListByCondition } from '../../../helpers/tree-helper';
import { generateString } from '../../../helpers/string-helper';
import { NEED_QUANTITY_MODE, NEED_TYPE } from '../../../constants/Needs';
import needService from '../../../services/need.service';
import { quantityUnits } from '../activity_config';


const { Text } = Typography;

const TabContainer = styled.div`
    .ant-tabs-content,
    .ant-tabs-tabpane {
        height: 100%;
    }
    .ant-spin-container {
        height: 100%;
    }
`;

const NeedsTab = ({ activity, cannotEdit }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'needs' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [activityNonConsummableNeeds, setActivityNonConsummableNeeds] = useState([]);
    const [activityConsummableNeeds, setActivityConsummableNeeds] = useState([]);
    const [saving, setSaving] = useState(false);
    const [selectedNonConsummableNeed, setSelectedNonConsummableNeed] = useState(null);
    const [selectedConsummableNeed, setSelectedConsummableNeed] = useState(null);
    const { activitiesDictionary, timeUnits } = useContext(PlanningContext);
    const globalNonConsummableNeeds = getArrayInListByCondition(
        useContext(NeedsContext).NeedList,
        'needType',
        NEED_TYPE.NON_CONSUMMABLE
    );
    const planningNonConsummableNeeds = getArrayInListByCondition(
        useContext(PlanningNeedsContext).NeedList,
        'needType',
        NEED_TYPE.NON_CONSUMMABLE
    );
    const globalConsummableNeeds = getArrayInListByCondition(
        useContext(NeedsContext).NeedList,
        'needType',
        NEED_TYPE.CONSUMMABLE
    );
    const planningConsummableNeeds = getArrayInListByCondition(
        useContext(PlanningNeedsContext).NeedList,
        'needType',
        NEED_TYPE.CONSUMMABLE
    );
    const activityData = activitiesDictionary[activity.serverId];

    const getAllocations = () => {
        setSaving(true);
        const activityAllocatedNeeds = activitiesDictionary[activity.serverId].allocatedNeed;

        const nonConsummableNeeds = [
            ...activityAllocatedNeeds.nonConsumable.global,
            ...activityAllocatedNeeds.nonConsumable.planning,
        ];
        console.log('🚀 ~ file: NeedsTab.jsx:92 ~ getAllocations ~ nonConsummableNeeds:', nonConsummableNeeds);
        const consummableNeeds = [
            ...activityAllocatedNeeds.consumable.global,
            ...activityAllocatedNeeds.consumable.planning,
        ];
        setSaving(false);
        return {
            nonConsummableNeeds,
            consummableNeeds,
        };
    };
    const getQuantityUnitName = (id) => {
        const quantityUnit = quantityUnits.find((unit) => unit.value === id);
        return quantityUnit ? quantityUnit.label : '-';
    };
    useEffect(() => {
        const allocations = getAllocations();
        setActivityNonConsummableNeeds(allocations.nonConsummableNeeds);
        setActivityConsummableNeeds(cloneDeep(allocations.consummableNeeds));
    }, [activitiesDictionary[activity.serverId].allocatedNeed]);

    const allocateNonConsummableNeed = useCallback(async () => {
        if (selectedNonConsummableNeed) {
            if (activityNonConsummableNeeds.find((i) => i.needId === selectedNonConsummableNeed)) {
                notificationError(t('already_allocated'));
                return;
            }
            setSaving(true);
            try {
                const newAllocation = await needService.createAllocatedNeed(
                    activityData.id,
                    selectedNonConsummableNeed,
                    { quantity: 1 }
                );
                console.log(
                    '🚀 ~ file: NeedsTab.jsx:203 ~ allocateNonConsummableNeed ~ newAlllocation:',
                    newAllocation
                );
                const newNeedAllocation = {
                    id: newAllocation.id,
                    needId: newAllocation.need.id,
                    needLabel: newAllocation.need.name,
                    quantity: newAllocation.quantity,
                };
                setActivityNonConsummableNeeds([...activityNonConsummableNeeds, newNeedAllocation]);
                setSelectedNonConsummableNeed(null);
                setSaving(false);
            } catch (error) {
                requestError(error, t('need_allocation'));
                setSaving(false);
            }
        } else {
            notificationError(generalTranslation('required_field'));
        }
    }, [selectedNonConsummableNeed, activityNonConsummableNeeds]);

    const handleEditAllocatedNeed = async (allocatedNeedId, data) => {
        try {
            setSaving(true);

            const updatedAllocation = await needService.updateAllocatedNeed(allocatedNeedId, data);
            console.log(
                '🚀 ~ file: NeedsTab.jsx:228 ~ handleEditAllocatedNeedQuantity ~ updatedAllocation:',
                updatedAllocation
            );
            setSaving(false);
        } catch (error) {
            setSaving(false);
            requestError(error, t('need_allocation'));
        }
    };

    const allocateConsummableNeed = useCallback(async () => {
        console.log(
            '🚀 ~ file: NeedsTab.jsx:240 ~ allocateConsummableNeed ~ selectedConsummableNeed:',
            selectedConsummableNeed
        );
        if (selectedConsummableNeed) {
            if (activityConsummableNeeds.find((i) => i.needId === selectedConsummableNeed)) {
                notificationError(t('already_allocated'));
                return;
            }
            setSaving(true);
            try {
                const selectedNeedObj = [...globalConsummableNeeds, ...planningConsummableNeeds].find(i => i.id === selectedConsummableNeed);
                const dataToSend = {
                    factor: 1,
                    mode: selectedNeedObj.quantityMode,
                }
                if (selectedNeedObj.quantityMode === NEED_QUANTITY_MODE.TEMPORAL) {
                    dataToSend.timeUnitId = selectedNeedObj.timeUnitId
                }
                const newAllocation = await needService.createAllocatedNeed(activityData.id, selectedConsummableNeed, dataToSend);
                console.log(
                    '🚀 ~ file: NeedsTab.jsx:203 ~ allocateNonConsummableNeed ~ newAlllocation:',
                    newAllocation
                );
                const newNeedAllocation = {
                    ...newAllocation,
                    needId: newAllocation.need.id,
                    needLabel: newAllocation.need.name,
                };
                setActivityConsummableNeeds([...activityConsummableNeeds, newNeedAllocation]);
                setSelectedConsummableNeed(null);
                setSaving(false);
            } catch (error) {
                requestError(error, t('need_allocation'));
                setSaving(false);
            }
        } else {
            notificationError(generalTranslation('required_field'));
        }
    }, [selectedConsummableNeed, activityConsummableNeeds]);

    const deleteNeedAllocation = async (allocatedNeedId) => {
        try {
            const updatedData = await needService.deleteAllocatedNeed(allocatedNeedId);
            console.log('🚀 ~ file: NeedsTab.jsx:262 ~ handleDeleteNeedAllocation ~ updatedData:', updatedData);
        } catch (error) {
            requestError(error, t('need_allocation'));
        }
    };

    const saveAllocatedNeed = async (allocatedNeedId, data, isNonConsummable = true) => {
        console.log('🚀 ~ file: NeedsTab.jsx:193 ~ saveAllocatedNeed ~ data:', data);
        const updateFct = (oldAllocations) => {
            const newAllocations = [...oldAllocations];
            const allocatedNeedIndex = newAllocations.findIndex((item) => item.id === allocatedNeedId);
            if (allocatedNeedIndex !== -1) {
                newAllocations[allocatedNeedIndex] = { ...newAllocations[allocatedNeedIndex], ...data };
            }
            return newAllocations;
        };
        if (isNonConsummable) {
            setActivityNonConsummableNeeds(updateFct);
        } else {
            setActivityConsummableNeeds(updateFct);
        }
        handleEditAllocatedNeed(allocatedNeedId, data);
    };

    const needQuantityModeOptions = [
        {
            value: NEED_QUANTITY_MODE.FIXE,
            label: t('quantity_mode_fix'),
        },
        {
            value: NEED_QUANTITY_MODE.QUANTITY,
            label: t('quantity_mode_quantity'),
        },
        {
            value: NEED_QUANTITY_MODE.TEMPORAL,
            label: t('quantity_mode_temporal'),
        },
    ];

    const columns = [
        {
            title: t('needs'),
            dataIndex: 'needLabel',
            key: 'need',
        },
        {
            title: t('quantity_mode'),
            key: 'mode',
            width: 100,
            render: (record) => (
                <Select
                    className="w-full"
                    defaultValue={record?.mode}
                    options={needQuantityModeOptions}
                    onChange={(value) => {
                        const dataToSend = { mode: value, factor: record.factor}
                        if (value === NEED_QUANTITY_MODE.TEMPORAL) {
                            dataToSend.timeUnitId = record.timeUnitId || timeUnits.find(i => i.name === 'H24')?.id; 
                        }
                        saveAllocatedNeed(record?.id, dataToSend , false)}}
                />
            ),
        },
        {
            title: t('time_unit'),
            key: 'unit',
            width: 100,
            render: (record) => record.mode === NEED_QUANTITY_MODE.TEMPORAL ? (
                <Select
                    className="w-full"
                    defaultValue={record?.timeUnitId}
                    options={timeUnits.reduce((agg, timeUnit) => {
                        agg.push({ label: timeUnit.name, value: timeUnit.id });

                        return agg;
                    }, [])}
                    onChange={(value) => saveAllocatedNeed(record?.id, { timeUnitId: value }, false)}
                />
            ) : '',
        },
        {
            title: t('factor'),
            dataIndex: '',
            key: 'factor',
            render: (record) => (
                <InputNumber
                    defaultValue={record?.factor}
                    disabled={cannotEdit}
                    type="number"
                    min={0}
                    onBlur={(e) => saveAllocatedNeed(record.id, { factor: e.target.value, mode: record.mode }, false)}
                />
            ),
        },
        {
            title: t('quantity'),
            // dataIndex: 'quantity',
            key: 'quantity',
            render: (record) => {
                const recordNeedObj = [...globalConsummableNeeds, ...planningConsummableNeeds].find(i => i.id === record.needId);
                let unit = getQuantityUnitName(recordNeedObj.quantityUnit);
                switch (record.mode) {
                    case NEED_QUANTITY_MODE.QUANTITY:
                        unit = `${getQuantityUnitName(activityData.quantityUnit)}/${getQuantityUnitName(recordNeedObj.quantityUnit)}`
                        break;
                    // case NEED_QUANTITY_MODE.TEMPORAL:
                    //     unit = `${getQuantityUnitName(recordNeedObj.quantityUnit)}/${getDurationUnitName(record.timeUnitId)}`
                    //     break;
                
                    default:
                        break;
                }
                return `${record.quantity} ${unit}`}
        },
        // {
        //     title: 'Unité',
        //     dataIndex: 'unit',
        //     key: 'unit',
        // },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (record) => (
                <Space>
                    {/* <Button
                        type="primary"
                        disabled={cannotEdit}
                        icon={<SaveOutlined />}
                        onClick={() => handleEditAllocatedNeed(record.id, record)}
                    /> */}
                    <Popconfirm
                        title={generalTranslation('delete_confirm')}
                        disabled={cannotEdit}
                        onCancel={() => {}}
                        okText={generalTranslation('yes')}
                        cancelText={generalTranslation('no')}
                    >
                        <Button
                            disabled={cannotEdit}
                            icon={<DeleteOutlined />}
                            onClick={() => deleteNeedAllocation(record.id)}
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <TabContainer className="h-full pt-2">
            <Spin spinning={saving} wrapperClassName="h-full">
                <div className="h-full flex flex-col justify-between">
                    <Card
                        size="small"
                        title={t('non_consummable')}
                        style={{ height: '49%' }}
                        className="overflow-y-auto"
                    >
                        <Row className="flex justify-between">
                            <Select
                                value={selectedNonConsummableNeed}
                                showSearch
                                onChange={(val) => setSelectedNonConsummableNeed(val)}
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                options={[
                                    {
                                        label: t('global_needs'),
                                        options: globalNonConsummableNeeds.map((need) => ({
                                            value: need.id,
                                            label: need.name,
                                        })),
                                    },
                                    {
                                        label: t('planning_needs'),
                                        options: planningNonConsummableNeeds.map((need) => ({
                                            value: need.id,
                                            label: need.name,
                                        })),
                                    },
                                ]}
                            />
                            <Button type="primary" disabled={cannotEdit} onClick={() => allocateNonConsummableNeed()}>
                                {t('allocate_need')}
                            </Button>
                        </Row>
                        <Row className="mt-2">
                            <List
                                size="small"
                                className="w-full h-full"
                                dataSource={activityNonConsummableNeeds}
                                renderItem={(item) => (
                                    <List.Item key={generateString(5)}>
                                        <Row className="flex w-full">
                                            <div className="w-1/2 flex items-center">
                                                <Text>{item.needLabel}</Text>
                                            </div>
                                            <div className="w-1/2 pl-2 flex justify-between">
                                                <InputNumber
                                                    className="mr-1"
                                                    type="number"
                                                    defaultValue={item.quantity}
                                                    disabled={cannotEdit}
                                                    min={0}
                                                    onBlur={(e) =>
                                                        saveAllocatedNeed(item.id, { quantity: Number(e.target.value) })
                                                    }
                                                    style={{ width: '80px' }}
                                                />
                                                <Space>
                                                    {/* <Button
                                                        type="primary"
                                                        disabled={cannotEdit}
                                                        icon={<SaveOutlined />}
                                                        onClick={() => handleEditAllocatedNeed(item.id, item)}
                                                    /> */}
                                                    <Popconfirm
                                                        title={generalTranslation('delete_confirm')}
                                                        disabled={cannotEdit}
                                                        onCancel={() => {}}
                                                        onConfirm={() => deleteNeedAllocation(item.id)}
                                                        okText={generalTranslation('yes')}
                                                        cancelText={generalTranslation('no')}
                                                    >
                                                        <Button disabled={cannotEdit} icon={<DeleteOutlined />} />
                                                    </Popconfirm>
                                                </Space>
                                            </div>
                                        </Row>
                                    </List.Item>
                                )}
                                bordered
                            />
                        </Row>
                    </Card>
                    <Card size="small" title={t('consummable')} style={{ height: '49%' }} className="overflow-y-auto">
                        <Row className="flex justify-between">
                            <Select
                                value={selectedConsummableNeed}
                                onChange={(val) => setSelectedConsummableNeed(val)}
                                showSearch
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                options={[
                                    {
                                        label: t('global_needs'),
                                        options: globalConsummableNeeds.map((need) => ({
                                            value: need.id,
                                            label: need.name,
                                        })),
                                    },
                                    {
                                        label: t('planning_needs'),
                                        options: planningConsummableNeeds.map((need) => ({
                                            value: need.id,
                                            label: need.name,
                                        })),
                                    },
                                ]}
                            />
                            <Button type="primary" disabled={cannotEdit} onClick={() => allocateConsummableNeed()}>
                                {t('allocate_need')}
                            </Button>
                        </Row>
                        <Table
                            columns={columns}
                            rowKey="id"
                            expandable={{
                                // expandedRowRender: (record) => (
                                //     <List
                                //         size="small"
                                //         className="w-full"
                                //         header={
                                //             <Row className="flex justify-between">
                                //                 <Col style={{ width: '14%' }}>Flux</Col>
                                //                 <Col style={{ width: '14%' }}>Mode</Col>
                                //                 <Col style={{ width: '14%' }}>Centre de coût</Col>
                                //                 <Col style={{ width: '14%' }}>Coût</Col>
                                //                 <Col style={{ width: '14%' }}>Devise</Col>
                                //                 <Col style={{ width: '14%' }}>Unité</Col>
                                //                 <Col style={{ width: '14%' }}>Distribution</Col>
                                //             </Row>
                                //         }
                                //         bordered
                                //     >
                                //         <List.Item>
                                //             <Row className="flex justify-between w-full">
                                //                 <Col style={{ width: '14%' }}>Dépense</Col>
                                //                 <Col style={{ width: '14%' }}>Coût unitaire</Col>
                                //                 <Col style={{ width: '14%' }}>Matériaux</Col>
                                //                 <Col style={{ width: '14%' }}>50</Col>
                                //                 <Col style={{ width: '14%' }}>€</Col>
                                //                 <Col style={{ width: '14%' }}>m3</Col>
                                //                 <Col style={{ width: '14%' }}>Linéaire</Col>
                                //             </Row>
                                //         </List.Item>
                                //     </List>
                                // ),
                                expandable: false,
                            }}
                            dataSource={activityConsummableNeeds}
                            className="mt-2"
                            size="small"
                        />
                    </Card>
                </div>
            </Spin>
        </TabContainer>
    );
};

NeedsTab.propTypes = {
    activity: PropTypes.object.isRequired,
    cannotEdit: PropTypes.bool.isRequired,
};

export default NeedsTab;
