import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    Row,
    List,
    Typography,
    Button,
    Form,
    Select,
    DatePicker,
    Col,
    InputNumber,
    TreeSelect,
    Popconfirm,
    Tabs,
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ActivityService from '../../../services/activity.service';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import { ResourceContext } from '../../../contexts/resource/ResourceContext';
import resourceService from '../../../services/resource.service';
import { mapObjectToArray } from '../../../helpers/object-helper';
import { notificationError } from '../../../helpers/notification';
import { findObjectInTree } from '../../../helpers/tree-helper';

const { Title } = Typography;
const { TreeNode } = TreeSelect;

const dateFormat = 'DD/MM/YYYY HH:mm';

const DrawerRow = styled(Row)`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    flex-grow: 1;

    & > * {
        flex-grow: 1;
    }
    .ant-tag {
        height: 1.5rem !important;
    }
`;

const TabContainer = styled.div`
    .ant-tabs-content,
    .ant-tabs-tabpane {
        height: 100%;
    }
`;

const ResourcesTab = ({ activity, cannotEdit }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation('translation', { keyPrefix: 'resource_tab' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [selectedResource, setSelectedResource] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [selectedResourceSkills, setSelectedResourceSkills] = useState(null);
    const [saving, setSaving] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [activeTab, setActiveTab] = useState('resourcetab-needs');
    const { activitiesDictionary} = useContext(PlanningContext);
    const { resourceList, skillList } = useContext(ResourceContext);
    const activityData = activitiesDictionary[activity.serverId];
    const [activityResources, setActivityResources] = useState(activityData.affectedResources);
    const groupedResourceList = (resources) =>
        resources.reduce((list, newResource) => {
            const newList = [...list];
            const indexInList = newList.findIndex((i) => i.resource.id === newResource.supplyId);
            if (indexInList === -1) {
                newList.push({
                    resource: findObjectInTree(newResource.supplyId, resourceList),
                    affectations: [
                        {
                            ...newResource,
                        },
                    ],
                });
            } else {
                newList[indexInList].affectations.push({
                    ...newResource,
                });
            }
            return newList;
        }, []);
    const [activityResourceList, setActivityResourceList] = useState([]);
    const [activitySkillNeedsList, setActivitySkillNeedsList] = useState(
        mapObjectToArray(activityData.neededSkillsQuantity, 'skillId', 'quantity', true)
    );
    useEffect(() => {
        if (activitiesDictionary[activity.serverId]) {
            setActivityResources(activitiesDictionary[activity.serverId].affectedResources);
            setActivitySkillNeedsList(
                mapObjectToArray(
                    activitiesDictionary[activity.serverId].neededSkillsQuantity,
                    'skillId',
                    'quantity',
                    true
                )
            );
        }
    }, [activitiesDictionary[activity.serverId]]);

    useEffect(() => {
        if (activityResources) {
            const sortedResources = activityResources.sort((a, b) =>
                moment(a.startDateTime).diff(moment(b.startDateTime))
            );
            setActivityResourceList(groupedResourceList(sortedResources));
        }
    }, [activityResources]);

    useEffect(() => {
        let skillOptions = [];
        if (selectedResource) {
            const resourceSkills = findObjectInTree(selectedResource, resourceList)?.skills || [];
            if (resourceSkills.length > 0) {
                skillOptions = resourceSkills.map((skill) => ({ label: skill.name, value: skill.id }));
            } else {
                skillOptions.push({ label: t('no_skill'), value: -1 });
            }
        }
        setSelectedResourceSkills(skillOptions);
    }, [selectedResource]);

    const handleSubmitAssignment = useCallback(
        async (values) => {
            try {
                const skillId = values.skillId !== -1 ? values.skillId : null;
                if (selectedItem.is_creation) {
                    await resourceService.createAssignment(
                        values.supplyId,
                        activity?.serverId,
                        skillId,
                        values.quantity,
                        values.startDateTime.clone().utc(true).valueOf(),
                        values.endDateTime.clone().utc(true).valueOf()
                    );
                    // // TODO change
                    // const newResource = {
                    //     ...values,
                    //     id: generateString(5),
                    //     resource: findObjectInTree(values.supplyId, resourceList),
                    // };
                    // setActivityResources((old) => {
                    //     const newValue = [...old, newResource];
                    //     const updatedData = {
                    //         ...activitiesDictionary[activity.id],
                    //         affectedResources: newValue,
                    //     };
                    //     updateActivitiesDictionary(activity.id, updatedData);
                    //     return newValue;
                    // });
                    setSelectedItem(null);
                    form.resetFields();
                } else {
                    await resourceService.updateAssignment(
                        selectedItem.id,
                        values.supplyId,
                        activity?.serverId,
                        skillId,
                        values.quantity,
                        values.startDateTime.clone().utc(true).valueOf(),
                        values.endDateTime.clone().utc(true).valueOf()
                    );
                }
            } catch (error) {
                notificationError(t('assignments'), generalTranslation('generic_error'));
                setSaving(false);
            }
        },
        [selectedItem, activityResources, activitiesDictionary]
    );
    const handleSubmitNeed = useCallback(
        async ({ skillId, quantity }) => {
            try {
                if (selectedItem.is_creation) {
                    const needsToSend = { ...activitiesDictionary[activity.serverId].neededSkillsQuantity };
                    needsToSend[skillId] = quantity;
                    const updatedData = await ActivityService.updateActivityNeededSkillQuantity(
                        activity.serverId,
                        needsToSend
                    );
                    setActivitySkillNeedsList(
                        mapObjectToArray(updatedData.neededSkillsQuantity, 'skillId', 'quantity', true)
                    );
                    setSelectedItem(null);
                    form.resetFields();
                } else {
                    const oldNeeds = [...activitySkillNeedsList];
                    const needObjectIndex = oldNeeds.findIndex((i) => i.skillId === skillId);
                    if (needObjectIndex > -1) {
                        oldNeeds[needObjectIndex].quantity = quantity;
                    }
                    const needsToSend = {};
                    oldNeeds.forEach((need) => {
                        needsToSend[need.skillId] = need.quantity;
                    });
                    await ActivityService.updateActivityNeededSkillQuantity(activity.serverId, needsToSend);
                    const updatedData = await ActivityService.updateActivityNeededSkillQuantity(
                        activity.serverId,
                        needsToSend
                    );
                    setActivitySkillNeedsList(
                        mapObjectToArray(updatedData.neededSkillsQuantity, 'skillId', 'quantity', true)
                    );
                    setSelectedItem(null);
                    form.resetFields();
                }
            } catch (e) {
                notificationError(t('needs'), generalTranslation('generic_error'));
                setSaving(false);
            }
        },
        [activitySkillNeedsList, selectedItem, activitiesDictionary]
    );

    const submitForm = async (values) => {
        if (saving) {
            return;
        }
        setSaving(true);
        if (activeTab === 'resourcetab-assignments') {
            await handleSubmitAssignment(values);
        } else {
            await handleSubmitNeed(values);
        }

        setSaving(false);
    };

    const createAssignment = () => {
        form.resetFields();
        setSelectedItem({
            is_creation: true,
            supplyId: null,
            skillId: null,
            startDateTime: moment(activity.startDate),
            endDateTime: moment(activity.endDate),
        });
        form.setFieldsValue({
            is_creation: true,
            supplyId: null,
            skillId: null,
            startDateTime: moment(activity.startDate),
            endDateTime: moment(activity.endDate),
        });
    };

    const createNeed = () => {
        form.resetFields();
        setSelectedItem({
            is_creation: true,
            skillId: null,
            quantity: 1,
        });
        form.setFieldsValue({
            is_creation: true,
            skillId: null,
            quantity: 1,
        });
    };

    const handleEditAssignment = (resourceObject) => {
        setSelectedResource(resourceObject.supplyId);
        setSelectedSkill(resourceObject.skillId || -1);
        setSelectedItem({
            is_creation: false,
            id: resourceObject.id,
            supplyId: resourceObject.supplyId,
            skillId: resourceObject.skillId || -1,
            quantity: resourceObject.quantity,
            startDateTime: moment(resourceObject.startDateTime).utc(),
            endDateTime: moment(resourceObject.endDateTime).utc(),
        });
        form.setFieldsValue({
            is_creation: false,
            supplyId: resourceObject.supplyId,
            skillId: resourceObject.skillId || -1,
            quantity: resourceObject.quantity,
            startDateTime: moment(resourceObject.startDateTime).utc(),
            endDateTime: moment(resourceObject.endDateTime).utc(),
        });
    };

    const handleDeleteAssignment = async (id) => {
        await resourceService.deleteAssignment(id);
    };
    const handleEditNeed = (needObject) => {
        setSelectedItem({
            is_creation: false,
            ...needObject,
        });
        form.setFieldsValue({
            is_creation: false,
            ...needObject,
        });
    };

    const handleDeleteNeed = async (skillId) => {
        const needsToSend = { ...activityData.neededSkillsQuantity };
        delete needsToSend[skillId];
        const updatedData = await ActivityService.updateActivityNeededSkillQuantity(activity.serverId, needsToSend);
        setActivitySkillNeedsList(mapObjectToArray(updatedData.neededSkillsQuantity, 'skillId', 'quantity', true));
        // updateActivitiesDictionary(activity.serverId, updatedData);
        // const oldNeeds = [...activitySkillNeedsList];
        // const needIndex = oldNeeds.findIndex((i) => i.skillId === skillId);
        // if (needIndex > -1) {
        //     oldNeeds.splice(needIndex, 1);
        // }
        // setActivitySkillNeedsList(oldNeeds);
        setSelectedItem(null);
        form.resetFields();
    };

    const renderTree = (node) => (
        <TreeNode value={node.id} title={node.label} key={node.id} selectable={!node.isLevel}>
            {node.children && node.children.map((child) => renderTree(child))};
        </TreeNode>
    );

    const ResourceTabs = [
        {
            label: t('needs'),
            key: 'resourcetab-needs',
            children: (
                <div className="flex h-full flex-col flex-grow">
                    <div className="flex justify-center">
                        <Button type="primary"
                                disabled={cannotEdit}
                                onClick={() => createNeed()}>
                            {t('add_need')}
                        </Button>
                    </div>
                    <DrawerRow>
                        <div
                            className="mt-3"
                            style={{
                                maxHeight: '100%',
                                overflowY: 'auto',
                            }}
                        >
                            <List
                                itemLayout="vertical"
                                dataSource={activitySkillNeedsList}
                                bordered
                                renderItem={(item) => (
                                    <List.Item
                                        className={`${
                                            selectedItem?.skillId === item.skillId ? 'bg-primary p-1' : 'p-1'
                                        }`}
                                    >
                                        <div className="flex w-full items-center">
                                            <div className="w-3/4">
                                                <div className="flex w-full">
                                                    <div className="w-1/4">
                                                        <label>
                                                            {skillList.find((i) => i.id === item.skillId)?.name}:{' '}
                                                        </label>
                                                    </div>
                                                    <div className="w-3/4">{item.quantity}</div>
                                                </div>
                                            </div>
                                            <div className="flex justify-between w-1/4">
                                                <Button
                                                    type="primary"
                                                    onClick={() => handleEditNeed(item)}
                                                    icon={<EditOutlined />}
                                                />
                                                <Popconfirm
                                                    title={generalTranslation('delete_confirm')}
                                                    disabled={cannotEdit}
                                                    onConfirm={() => handleDeleteNeed(item.skillId)}
                                                    onCancel={() => {}}
                                                    okText={generalTranslation('yes')}
                                                    cancelText={generalTranslation('no')}
                                                >
                                                    <Button
                                                        disabled={cannotEdit}
                                                        icon={<DeleteOutlined />} />
                                                </Popconfirm>
                                            </div>
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </div>
                        {selectedItem && (
                            <div
                                className="w-full bg-white flex items-end"
                                style={{
                                    borderTop: '1px solid #e9e9e9',
                                    padding: '10px 16px',
                                    height: 'auto',
                                    flexGrow: 'unset',
                                }}
                            >
                                <Form
                                    form={form}
                                    layout="vertical"
                                    className="w-full"
                                    initialValues={selectedItem}
                                    disabled={cannotEdit}
                                    onFinish={submitForm}
                                >
                                    <Form.Item
                                        label={t('skill')}
                                        name="skillId"
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={
                                                selectedItem.is_creation
                                                    ? skillList
                                                          .filter(
                                                              (i) =>
                                                                  activitySkillNeedsList.find(
                                                                      (skill) => skill.skillId === i.id
                                                                  ) === undefined
                                                          )
                                                          .map((skill) => ({ label: skill.name, value: skill.id }))
                                                    : skillList.map((skill) => ({ label: skill.name, value: skill.id }))
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t('resource_quantity')}
                                        name="quantity"
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={1} />
                                    </Form.Item>
                                    <Row gutter={16} className="mt-2">
                                        <Col span={24} className="flex justify-between">
                                            <Button
                                                style={{ marginRight: 8 }}
                                                onClick={() => {
                                                    setSelectedItem(null);
                                                    form.resetFields();
                                                }}
                                            >
                                                {generalTranslation('cancel')}
                                            </Button>
                                            <Button type="primary" loading={saving} onClick={() => form.submit()}>
                                                {generalTranslation('save')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        )}
                    </DrawerRow>
                </div>
            ),
        },
        {
            label: t('assignment'),
            key: 'resourcetab-assignments',
            children: (
                <div className="flex h-full flex-col flex-grow">
                    <div className="mt-3 flex justify-center">
                        <Button type="primary"
                                disabled={cannotEdit}
                                onClick={() => createAssignment()}>
                            {t('add_assignment')}
                        </Button>
                    </div>
                    <DrawerRow>
                        <div
                            className="mt-3"
                            style={{
                                maxHeight: '100%',
                                overflowY: 'auto',
                            }}
                        >
                            <List
                                itemLayout="vertical"
                                bordered
                                dataSource={activityResourceList}
                                renderItem={(item) => (
                                    <List.Item
                                        className={`${
                                            selectedItem?.supplyId === item.resource.id ? 'bg-primary p-2' : ''
                                        }`}
                                    >
                                        <div className="flex justify-between p-2">
                                            <Title level={5}>{item.resource?.label}</Title>
                                        </div>
                                        {item.affectations.map((affectation) => (
                                            <div key={affectation.id} className="flex w-full mb-2">
                                                <div className="w-3/4">
                                                    <div className="flex w-full">
                                                        <div className="w-1/4">
                                                            <label>{t('assignment_start')}: </label>
                                                        </div>
                                                        <div className="w-3/4">
                                                            {moment(affectation.startDateTime).utc().format(dateFormat)}
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full">
                                                        <div className="w-1/4">
                                                            <label>{t('assignment_end')}: </label>
                                                        </div>
                                                        <div className="w-3/4">
                                                            {moment(affectation.endDateTime).utc().format(dateFormat)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-between w-1/4">
                                                    <Button
                                                        type="primary"
                                                        onClick={() => handleEditAssignment(affectation)}
                                                        icon={<EditOutlined />}
                                                    />
                                                    <Popconfirm
                                                        title={generalTranslation('delete_confirm')}
                                                        disabled={cannotEdit}
                                                        onConfirm={() => handleDeleteAssignment(affectation.id)}
                                                        onCancel={() => {}}
                                                        okText={generalTranslation('yes')}
                                                        cancelText={generalTranslation('no')}
                                                    >
                                                        <Button
                                                            disabled={cannotEdit}
                                                            icon={<DeleteOutlined />} />
                                                    </Popconfirm>
                                                </div>
                                            </div>
                                        ))}
                                    </List.Item>
                                )}
                            />
                        </div>
                        {selectedItem && (
                            <div
                                className="w-full bg-white flex items-end"
                                style={{
                                    borderTop: '1px solid #e9e9e9',
                                    padding: '10px 16px',
                                    height: 'auto',
                                    flexGrow: 'unset',
                                }}
                            >
                                <Form
                                    form={form}
                                    layout="vertical"
                                    className="w-full"
                                    disabled={cannotEdit}
                                    initialValues={selectedItem}
                                    onFinish={submitForm}
                                >
                                    <Form.Item
                                        label={t('supply')}
                                        name="supplyId"
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            },
                                        ]}
                                    >
                                        <TreeSelect
                                            showSearch
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            allowClear
                                            treeDefaultExpandAll
                                            onChange={(value) => setSelectedResource(+value)}
                                        >
                                            {resourceList.length && renderTree(resourceList[0])}
                                        </TreeSelect>
                                    </Form.Item>
                                    <Form.Item
                                        label={t('skill')}
                                        name="skillId"
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            },
                                        ]}
                                    >
                                        <Select
                                            value={selectedSkill}
                                            onChange={(value) => setSelectedSkill(+value)}
                                            options={selectedResourceSkills}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t('assignment_start')}
                                        name="startDateTime"
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            format={
                                                window.dateStringFormat.concat(' HH:mm')
                                            }
                                            showTime={{ format: 'HH:mm' }}
                                            className="w-full"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t('assignment_end')}
                                        name="endDateTime"
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            format={
                                                window.dateStringFormat.concat(' HH:mm')
                                            }
                                            showTime={{ format: 'HH:mm' }}
                                            className="w-full"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={t('resource_quantity')}
                                        name="quantity"
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            },
                                        ]}
                                    >
                                        <InputNumber min={1} />
                                    </Form.Item>
                                    <Row gutter={16} className="mt-2">
                                        <Col span={24} className="flex justify-between">
                                            <Button
                                                style={{ marginRight: 8 }}
                                                onClick={() => {
                                                    setSelectedItem(null);
                                                    form.resetFields();
                                                }}
                                            >
                                                {generalTranslation('cancel')}
                                            </Button>
                                            <Button type="primary" loading={saving} onClick={() => form.submit()}>
                                                {generalTranslation('save')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        )}
                    </DrawerRow>
                </div>
            ),
        },
    ];

    return (
        <TabContainer className="h-full">
            <Tabs
                defaultActiveKey="resourcetab-needs"
                items={ResourceTabs}
                className="h-full"
                destroyInactiveTabPane
                onChange={(key) => {
                    setActiveTab(key);
                    setSelectedItem(null);
                    form.resetFields();
                }}
            />
        </TabContainer>
    );
};

ResourcesTab.propTypes = {
    activity: PropTypes.object.isRequired,
    cannotEdit: PropTypes.bool.isRequired
};

export default ResourcesTab;
