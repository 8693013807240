import {fabric} from 'fabric';

const distanceAxisGenerator = (distanceCanvas) => {
    if (distanceCanvas) {
        // operations for creating distance vertical lines
        let currentX = 0;
        while (currentX < window.innerWidth * 2) {
            const line = new fabric.Line([currentX, 0, currentX, window.innerHeight * 2], {
                strokeDashArray: [2, 2],
                stroke: 'gray',
                strokeWidth: 1,
                strokeUniform: true,
            });
            distanceCanvas.add(line);
            currentX += 100;
        }
        distanceCanvas.renderAll();
    }
}

export default distanceAxisGenerator;