import * as moment from 'moment';
import { AntDValueEditor } from '@react-querybuilder/antd';
import { AutoComplete, DatePicker, Input, InputNumber, Select, TreeSelect } from 'antd';
// import { isNil } from 'lodash';
import { generateString } from '../../../helpers/string-helper';
import { FIELD_INPUT_TYPE, conditionEnumNoTranslation } from '../FilterUtils';
import TreeAutocomplete from '../../common/forms/TreeAutocomplete';

const { SHOW_PARENT } = TreeSelect;
/* eslint-disable */

const CustomValueEditor = (props) => {
    const conditions = conditionEnumNoTranslation;
    if (props.inputType === FIELD_INPUT_TYPE.START_DATE) {
        return (
            <div>
                <DatePicker
                    className="w-full"
                    defaultValue={!props.value ? null : moment(props.value)}
                    format={window.dateStringFormat.concat(' HH:mm')}
                    allowClear={false}
                    showTime={{
                        defaultValue: moment('00:00:00', 'HH:mm:ss'),
                        format: 'HH:mm',
                    }}
                    onChange={(value) => props.handleOnChange(value.clone().valueOf())}
                />
            </div>
        );
    }
    if (props.inputType === FIELD_INPUT_TYPE.END_DATE) {
        return (
            <div>
                <DatePicker
                    className="w-full"
                    defaultValue={!props.value ? null : moment(props.value)}
                    format={window.dateStringFormat.concat(' HH:mm')}
                    allowClear={false}
                    showTime={{
                        defaultValue: moment('23:59:59', 'HH:mm:ss'),
                        format: 'HH:mm',
                    }}
                    onChange={(value) => props.handleOnChange(value.clone().valueOf())}
                />
            </div>
        );
    }
    if (props.inputType === FIELD_INPUT_TYPE.SEARCH_SELECT) {
        const defaultValue = props.value;

        // if (isNil(props.value) || props.value === '') {
        //     defaultValue = props.values[0].value;
        //     props.handleOnChange(defaultValue);
        // }
        return (
            <div key={generateString(5)}>
                <Select
                    style={{ width: '200px' }}
                    showSearch
                    optionFilterProp="label"
                    defaultValue={defaultValue}
                    onChange={(value) => props.handleOnChange(value)}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={props.values}
                />
            </div>
        );
    }
    if (props.inputType === FIELD_INPUT_TYPE.MULTISELECT) {
        const defaultValue = props.value;

        // if (isNil(props.value) || props.value === '') {
        //     defaultValue = props.values[0].value;
        //     props.handleOnChange(defaultValue);
        // }
        return (
            <div key={generateString(5)}>
                <Select
                    style={{ minWidth: '200px' }}
                    mode="multiple"
                    showSearch
                    optionFilterProp="label"
                    defaultValue={defaultValue}
                    onChange={(value) => props.handleOnChange(value)}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={props.values}
                />
            </div>
        );
    }
    if (props.inputType === FIELD_INPUT_TYPE.MULTI_TREESELECT) {
        const defaultValue = props.value;
        const fieldNames = props.fieldData.fieldNames;

        // if (isNil(props.value) || props.value === '') {
        //     defaultValue = props.values[0].value;
        //     props.handleOnChange(defaultValue);
        // }
        return (
            <div key={generateString(5)}>
                <TreeSelect
                    style={{ minWidth: '200px' }}
                    multiple
                    showSearch
                    treeCheckable
                    showCheckedStrategy={SHOW_PARENT}
                    defaultValue={defaultValue}
                    fieldNames={fieldNames}
                    onChange={(value) => props.handleOnChange(value)}
                    filterTreeNode={(input, node) =>
                        (node[fieldNames.label] ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    treeData={props.values}
                />
            </div>
        );
    }
    if (props.inputType === FIELD_INPUT_TYPE.NUMBER_MIN_MAX) {
        const defaultValue = props.value;
        // if (isNil(props.value) || props.value === '') {
        //     defaultValue = 0;
        //     props.handleOnChange(0);
        // }

        return (
            <div>
                <InputNumber
                    defaultValue={defaultValue}
                    min={props.fieldData.min}
                    max={props.fieldData.max}
                    onChange={(value) => props.handleOnChange(value)}
                />
            </div>
        );
    }

    if (props.inputType === FIELD_INPUT_TYPE.SELECT_OR_INPUT) {
        const defaultValue = props.value;

        // if (isNil(props.value) || props.value === '') {
        //     defaultValue = props.values[0].value;
        //     props.handleOnChange(defaultValue);
        // }
        return (
            <div>
                <AutoComplete
                    allowClear
                    value={defaultValue}
                    style={{ minWidth: 200 }}
                    options={props.values}
                    onChange={(value) => props.handleOnChange(value)}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                />
            </div>
        );
    }
    if (props.inputType === FIELD_INPUT_TYPE.TREESELECT_OR_INPUT) {
        return (
            <div>
                <TreeAutocomplete {...props}/>
            </div>
        );
    }
    if (props.inputType === FIELD_INPUT_TYPE.NUMBER_MIN_MAX_SELECT) {
        const defaultValue = props.value;
        // // save on first time
        // if (typeof props.value !== "object") {
        //     props.handleOnChange(defaultValue);
        // }
        return (
            <div className="flex">
                <InputNumber
                    className="mr-2"
                    defaultValue={defaultValue.value}
                    min={props.fieldData.min}
                    max={props.fieldData.max}
                    onChange={(value) =>
                        props.handleOnChange({
                            value,
                            unit: props.value.unit,
                        })
                    }
                />
                <Select
                    style={{ minWidth: '100px' }}
                    defaultValue={defaultValue.unit}
                    options={props.fieldData.selectChoices}
                    onChange={(val) =>
                        props.handleOnChange({
                            value: props.value.value,
                            unit: val,
                        })
                    }
                />
            </div>
        );
    }
    if (props.fieldData.isSimpleChoice && (props.operator === conditions.LIKE.name || props.operator === conditions.NOT_LIKE.name)) {
        return (
            <div>
                <Input
                    defaultValue={props.value}
                    onChange={(e) => props.handleOnChange(e.target.value)}
                />
            </div>
        );
    }
    if (props.fieldData.isSimpleChoice && (props.operator === conditions.EQUAL.name || props.operator === conditions.DIFFERENT.name)) {
        const defaultValue = props.value;

        // if (isNil(props.value) || props.value === '' || !choiceValidator(props, props.fieldData.selectChoices, 'value')) {
        //     defaultValue = props.fieldData.selectChoices[0].value;
        //     props.handleOnChange(props.fieldData.selectChoices[0].value)
        // }
        return (
            <div key={generateString(5)}>
                <Select
                    style={{minWidth: '100px'}}
                    defaultValue={defaultValue}
                    options={props.values}
                    onChange={(val) => props.handleOnChange(val)}
                />
            </div>
        );
    }
    const originalProps = { ...props };
    delete originalProps.hideHours;
    return <AntDValueEditor {...originalProps} />;
};
export default CustomValueEditor;
