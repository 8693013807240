import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAllShapes,
    shapesReceived,
    updateShape,
} from '../../../store/slices/shape.slice';
import { generateFabricRandomPolygon } from '../../../store/utils/ShapeUtils';
import { FabricJSCanvas, useFabricJSEditor } from '../../fabricjs-wrapper';
// Component containing the railway shapes, managing updates
const RailwayPane = ({handleCanvasReady}) => {
    const [shapeCount] = useState(300);
    const dispatch = useDispatch();
    const shapes = useSelector(selectAllShapes)

    const { editor, onReady } = useFabricJSEditor();
    useEffect(() => {
        const mockData = [...Array(shapeCount).keys()].map(() => {
            const data = generateFabricRandomPolygon();
            
            // dispatch(addShape(data))
            return data;
        });
        dispatch(shapesReceived({ shapes: mockData }));
    }, [shapeCount]);
    useEffect(() => {
        console.log("🚀 ~ addShapes ~ editor:", editor, shapes)
        if (editor) {
            shapes.forEach((data) => {
                const canvasObject = editor.addPolygon(data);
                canvasObject?.on('modified', (e) => {
                    const newData = e.target.toObject(['id']);
                    dispatch(updateShape(newData))
                })
            })
        }
    }, [editor])


    const handleReady = (canvas) => {
        onReady(canvas);
        if (handleCanvasReady) {
            handleCanvasReady(canvas);
        }
    }


    return (
                <FabricJSCanvas className="absolute top-0 sample-canvas w-full h-full" onReady={handleReady} />
    );
};

export default RailwayPane;
