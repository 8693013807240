import { fabric } from 'fabric';
import { useEffect, useMemo, useState } from 'react';
import { TEXT_DEFAULT_OPTION } from './defaultValues';

/**
 * Creates editor
 */
const buildEditor = (
    canvas,
    scaleStep
) => ({
        canvas,
        addCircle: (options) => {
            if (options) {
            const object = new fabric.Circle(options);
            canvas.add(object);
        } else {
            throw new Error('Options required')
        }
        },
        addRectangle: (options) => {
            if (options) {
            const object = new fabric.Rect(options);
            canvas.add(object);
        } else {
            throw new Error('Options required')
        }
        },
        addPolygon: (options) => {
            if (options) {
                const object = new fabric.Polygon(options.points, 
                    options.options
                );
                canvas.add(object);
                return object;
            } 
                throw new Error('Options required')
           
        },
        addLine: (options) => {
            if (options) {
                const object = new fabric.Line(options.points, 
                    options.options,
                );
                canvas.add(object);
            } else {
                throw new Error('Options required')
            }
           
        },
        addText: (text, textOptions) => {
            // use stroke in text fill, fill default is most of the time transparent
            const object = new fabric.Textbox(text, { ...(textOptions || TEXT_DEFAULT_OPTION) });
            object.set({ text });
            canvas.add(object);
        },
        deleteAll: () => {
            canvas.getObjects().forEach((object) => canvas.remove(object));
            canvas.discardActiveObject();
            canvas.renderAll();
        },
        deleteSelected: () => {
            canvas.getActiveObjects().forEach((object) => canvas.remove(object));
            canvas.discardActiveObject();
            canvas.renderAll();
        },
        setFillColor: (fill) => {
            canvas.getActiveObjects().forEach((object) => object.set({ fill }));
            canvas.renderAll();
        },
        setStrokeColor: (stroke) => {
            canvas.getActiveObjects().forEach((object) => {
                if (object.type === TEXT_DEFAULT_OPTION.type) {
                    // use stroke in text fill
                    object.set({ fill: stroke });
                    return;
                }
                object.set({ stroke });
            });
            canvas.renderAll();
        },
        zoomIn: () => {
            const zoom = canvas.getZoom();
            canvas.setZoom(zoom / scaleStep);
        },
        zoomOut: () => {
            const zoom = canvas.getZoom();
            canvas.setZoom(zoom * scaleStep);
        },
    });


const useFabricJSEditor = (props = {}) => {
    const scaleStep = props.scaleStep || 0.5;
    const [canvas, setCanvas] = useState(null);
    const [selectedObjects, setSelectedObject] = useState([]);
    useEffect(() => {
        const bindEvents = (canvasElement) => {
            canvasElement.on('selection:cleared', () => {
                setSelectedObject([]);
            });
            canvasElement.on('selection:created', (e) => {
                setSelectedObject(e.selected);
            });
            canvasElement.on('selection:updated', (e) => {
                setSelectedObject(e.selected);
            });
        };
        if (canvas) {
            bindEvents(canvas);
        }
    }, [canvas]);

    const editor = useMemo(
        () =>
            canvas ? buildEditor(canvas, scaleStep) : undefined,
        [canvas]
    );
    return {
        selectedObjects,
        onReady: (canvasReady) => {
            console.log('Fabric canvas ready', canvasReady);
            setCanvas(canvasReady);
        },
        editor,
    };
};

export { buildEditor, useFabricJSEditor };
