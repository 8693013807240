import React, { useEffect, useRef } from 'react'
import { fabric } from 'fabric'
import { useFabricJSEditor } from './editor'



/**
 * Fabric canvas as component
 */
const FabricJSCanvas = ({ className, onReady, isStatic = false }) => {
  const canvasEl = useRef(null)
  const canvasElParent = useRef(null)
  useEffect(() => {
    const canvas = isStatic ? new fabric.StaticCanvas(canvasEl.current) : new fabric.Canvas(canvasEl.current) 
    canvas.selection = false;
    const setCurrentDimensions = () => {
      canvas.setHeight(canvasElParent.current?.clientHeight || 0)
      canvas.setWidth(canvasElParent.current?.clientWidth || 0)
      canvas.renderAll()
    }
    const resizeCanvas = () => {
      setCurrentDimensions()
    }
    setCurrentDimensions()

    window.addEventListener('resize', resizeCanvas, false)

    if (onReady) {
      onReady(canvas)
    }

    return () => {
      canvas.dispose()
      window.removeEventListener('resize', resizeCanvas)
    }
  }, [])
  return (
    <div ref={canvasElParent} className={className}>
      <canvas ref={canvasEl} />
    </div>
  )
}

export { FabricJSCanvas, useFabricJSEditor }