/* eslint-disable */
import React, {  useRef } from 'react';
import { Row } from 'antd';

import RailwayPane from './pane/RailwayPane';
import TimeAxis from './axis/TimeAxis';
import DistanceAxis from './axis/DistanceAxis';
import { FabricJSCanvas } from '../fabricjs-wrapper';
import timeAxisGenerator from './axis/TimeAxisGenerator';
import distanceAxisGenerator from './axis/DistanceAxisGenerator';

const RailwayContainer = () => {
    const mainCanvas = useRef(null);
    const distanceCanvas = useRef(null);
    const timeCanvas = useRef(null);
    const onZoomChange = (canvas)=> {
        let w = 1; // desired width in pixels
        let strokeWidth = w / canvas.getZoom();
        for (let object of canvas.getObjects()) {
          object.set('strokeWidth', strokeWidth);
          object.set('strokeDashArray', [2,2]);
          // can also update other things here
        }
      }
    const handleReadyDistanceCanvas = (canvas) => {
        // onReady(canvas);
        distanceCanvas.current = canvas;
        distanceAxisGenerator(canvas)
        canvas.on('after:render', () => onZoomChange(canvas))
        // dispatch(canvasSet({canvas}));
    };
    const handleReadyTimeCanvas = (canvas) => {
        console.log("🚀 ~ handleReadyTimeCanvas ~ canvas:", canvas)
        // onReady(canvas);
        timeCanvas.current = canvas;
        timeAxisGenerator(canvas)
        canvas.on('after:render', () => onZoomChange(canvas))
        // dispatch(canvasSet({canvas}));
    };
    const handleRailwayPaneCanvasReady = (canvas) => {
        mainCanvas.current = canvas;
        canvas.on('mouse:down', function (opt) {
            var evt = opt.e;
            if (evt.altKey === true) {
                this.isDragging = true;
                this.selection = false;
                this.lastPosX = evt.clientX;
                this.lastPosY = evt.clientY;
            }
        });
        canvas.on('mouse:move', function (opt) {
            if (this.isDragging) {
                var e = opt.e;
                var vpt = this.viewportTransform;
                vpt[4] += e.clientX - this.lastPosX;
                vpt[5] += e.clientY - this.lastPosY;
                timeCanvas.current.viewportTransform = [...vpt];
                distanceCanvas.current.viewportTransform = [...vpt];
                this.requestRenderAll();
                timeCanvas.current.requestRenderAll();
                distanceCanvas.current.requestRenderAll();
                this.lastPosX = e.clientX;
                timeCanvas.current.lastPosX = this.lastPosX;
                distanceCanvas.current.lastPosX = this.lastPosX;
                this.lastPosY = e.clientY;
                timeCanvas.current.lastPosY = this.lastPosY;
                distanceCanvas.current.lastPosY = this.lastPosY;
            }
        });
        canvas.on('mouse:up', function (opt) {
            // on mouse up we want to recalculate new interaction
            // for all objects, so we call setViewportTransform
            this.setViewportTransform(this.viewportTransform);
            this.isDragging = false;
            this.selection = true;
        });
        canvas.on('mouse:wheel', function (opt) {
            var delta = opt.e.deltaY;
            var zoom = canvas.getZoom();
            zoom *= 0.999 ** delta;
            if (zoom > 20) zoom = 20;
            if (zoom < 0.01) zoom = 0.01;
            canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            timeCanvas.current.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            distanceCanvas.current.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();
        });
        // dispatch(canvasSet({canvas}));
    };

    return (
        <div className="w-full h-full flex flex-grow flex-col">
            <Row className="flex-grow w-full">
                <div className="relative border overflow-auto w-full h-full">
                    <FabricJSCanvas isStatic
                        className="absolute top-0 sample-canvas w-full h-full"
                        onReady={handleReadyDistanceCanvas}
                    />
                    <FabricJSCanvas isStatic
                        className="absolute top-0 sample-canvas w-full h-full"
                        onReady={handleReadyTimeCanvas}
                    />
                    <RailwayPane handleCanvasReady={handleRailwayPaneCanvasReady} />
                </div>
            </Row>
        </div>
    );
};

export default RailwayContainer;
