import { fabric } from 'fabric';

const  timeAxisGenerator = (timeCanvas) => {
    if (timeCanvas) {
        console.log("🚀 ~ useEffect ~ timeCanvas:", timeCanvas)
        // operations for creating horizontal lines
        let currentY = 0;
        while (currentY < window.innerHeight * 2) {
            const line = new fabric.Line([0, currentY, window.innerWidth * 2, currentY], {
                stroke: 'gray',
                strokeDashArray: [2, 2],
                strokeWidth: 1,
                strokeUniform: true,
            });
            timeCanvas.add(line);
            currentY += 20;
        }
        timeCanvas.renderAll();
    }
}

export default timeAxisGenerator;