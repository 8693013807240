import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import PropTypes from 'prop-types';
import AuthService from '../../services/auth.service';
import TeamService from '../../services/team.service';
import { requestError } from '../../helpers/notification';
import { DATE_FORMAT_EN, DATE_FORMAT_FR } from '../../constants/Generic';
// import userService from '../../services/user.service';

export const UserContext = createContext({
    userPreferences: null,
    updateUserPreferences: () => {},
    refreshUserPreferences: () => {},
    currentUser: {},
    updatingData: false,
    broadcastUser: {},
    updatedUserId: 0,
    deconnexionTime: null,
});

export const UserContextProvider = ({ children }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'user_context' });
    const [userPreferences, setUserPreferences] = useState(null);
    const [currentUser, setCurrentUser] = useState({});
    const [updatingData, setUpdatingData] = useState(false);
    const [canFetchData, setCanFetchData] = useState(false);
    const [deconnexionTime, setDeconnexionTime] = useState(null);

    const computeDateFormat = (preferences) => {
        if (preferences.dateFormat) {
            window.dateStringFormat = preferences.dateFormat.replaceAll('_', preferences.dateSeparator);
            window.datepickerFormat = window.dateFormat.convert(
                window.dateStringFormat,
                window.dateFormat.momentJs,
                window.dateFormat.datepicker
            );
        } else {
            switch (localStorage.getItem('lng')) {
                case 'en':
                    window.dateStringFormat = DATE_FORMAT_EN.replaceAll('_', '-');
                    break;
                case 'fr':
                    window.dateStringFormat = DATE_FORMAT_FR.replaceAll('_', '/');

                    break;

                default:
                    window.dateStringFormat = DATE_FORMAT_FR.replaceAll('_', '/');

                    break;
            }
            window.datepickerFormat = window.dateFormat.convert(
                window.dateStringFormat,
                window.dateFormat.momentJs,
                window.dateFormat.datepicker
            );
        }
    };

    const updateUserPreferences = (newPreferences) =>
        new Promise((resolve) => {
            const updatedPreference = Object.assign(userPreferences, newPreferences);
            setUserPreferences(() => ({ ...updatedPreference }));
            AuthService.updateUserPreferences(updatedPreference)
                .then((userPreferencesUpdated) => {
                    computeDateFormat(userPreferencesUpdated);
                    resolve(userPreferencesUpdated);
                })
                .catch((error) => {
                    requestError(error, t('error_update_preference'));
                    resolve(userPreferences);
                });
        });

    const refreshUserPreferences = async () => {
        await AuthService.getPreferences()
            .then((userPreferencesList) => {
                computeDateFormat(userPreferencesList);
                setUserPreferences(userPreferencesList);
            })
            .catch((error) => {
                console.log('🚀 ~ file: UserContext.jsx:186 ~ error', error);
                if (error?.response?.data?.type !== 'no_preference_found') {
                    requestError(error, t('error_get_preference'));
                }
                setUserPreferences({});
            });
        console.log('userPreferences got');
    };

    // using SSE for updating infos
    const [listeningBroadcastUser, setListeningBroadcastUser] = useState(false);
    // const [listeningBroadcastTeam, setListeningBroadcastTeam] = useState(false);
    const [userBroadcastMsg, setUserBroadcastMsg] = useState(false);
    const [updatedUserId, setUpdatedUserId] = useState(0);

    const handleBroadcastMsg = async (event) => {
        // console.log('🚀 ~ handleBroadcastMsg ~ event USER:', event);
        try {
            setUpdatedUserId(0);
            const data = JSON.parse(event.data);
            const user = AuthService.getCurrentUser();
            // user updated
            if (data.type === 'User') {
                setUpdatingData(true);
                setUpdatedUserId(data.id);
                if (data.id === user.userId) {
                    console.log('should update');
                    await AuthService.getSession();
                    const updatedUser = AuthService.getCurrentUser();
                    setCurrentUser({ ...updatedUser, jobName: updatedUser?.job?.name });
                }
                setUpdatingData(false);
            }
            if (data.type === 'Preference' && data.user !== user.userId && data.id === user.userId) {
                console.log('should update user preferences', data, currentUser.id);
                await refreshUserPreferences();
            }
        } catch (error) {
            console.log('🚀 ~ handleBroadcastMsg ~ error:');
        }
    };

    const [teamList, setTeamList] = useState([]);
    const [updatedTeam, setUpdatedTeam] = useState(null);
    const handleBroadcastTeamsMsg = async (event) => {
        // console.log('🚀 ~ handleBroadcastTeamsMsg ~ event: TEAMS', event);
        try {
            const data = JSON.parse(event.data);
            if (data.type === "Team") {

                // refresh list
                const teams = await TeamService.listTeams(false);
                const updatedTeamObject = teams.find((i) => i.id === +data.id);
                if (updatedTeamObject) {
                    setUpdatedTeam(updatedTeamObject);
                } else {
                    setUpdatedTeam({ id: data.id });
                }
                const indexTeam = teamList.findIndex((i) => i.id === +data.id);
                if (indexTeam !== -1 && updatedTeamObject) {
                    const cloneTeam = [...teamList];
                    cloneTeam[indexTeam] = updatedTeamObject;
                    setTeamList(cloneTeam);
                } else {
                    setTeamList(teams);
                }
            }
        } catch (error) {
            console.log('🚀 ~ handleBroadcastTeamsMsg ~ error:');
        }
    };
    // broadcast user
    useEffect(() => {
        if (userBroadcastMsg) {
            handleBroadcastMsg(userBroadcastMsg);
        }
    }, [userBroadcastMsg]);

    useEffect(() => {
        (async () => {
            if (!listeningBroadcastUser && window._env_.REACT_APP_USERS_API && canFetchData) {
                if (teamList.length === 0) {
                    const teams = await TeamService.listTeams(false);
                    setTeamList(teams);
                }
                const accessToken = localStorage.getItem('accessToken');
                const headers = {};
                if (accessToken) {
                    headers.Authorization = `Bearer ${accessToken}`;
                    headers.Accept = 'text/event-stream';
                }
                await fetchEventSource(`${window._env_.REACT_APP_USERS_API}/broadcast`, {
                    openWhenHidden: true,
                    headers,
                    onopen: () => {
                        console.log('CONNECTED TO BROADCAST TEAM');
                    },
                    onmessage: (e) => {
                        setUserBroadcastMsg(e);
                        handleBroadcastTeamsMsg(e);
                    },
                    onerror: () => {},
                    onclose: () => {
                        console.log('BROADCAST USER TEAM USER CLOSED');
                    },
                });
                setListeningBroadcastUser(true);
            }
        })();
    }, [listeningBroadcastUser, canFetchData]);

    // broadcast team
    // useEffect(() => {
    //     (async () => {
    //         if (!listeningBroadcastTeam && window._env_.REACT_APP_TEAMS_API && canFetchData) {
    //             eventSourceTeam = new EventSource(`${window._env_.REACT_APP_TEAMS_API}/broadcast`);

                

    //             eventSourceTeam.onmessage = handleBroadcastTeamsMsg;
    //             eventSourceTeam.onerror = (event) => {
    //                 if (event.target.readyState === EventSource.CLOSED) {
    //                     console.log('SSE teams closed ()');
    //                 }
    //                 eventSourceTeam.close();
    //                 // reconnect
    //                 setListeningBroadcastTeam(false);
    //             };

    //             eventSourceTeam.onopen = (event) => {
    //                 console.log('connection teams opened', event);
    //             };
    //             setListeningBroadcastTeam(true);
    //         }
    //     })();
    // }, [listeningBroadcastTeam, canFetchData]);

    /* eslint-disable-next-line */
    useEffect(() => {
        (async () => {
            const programInfo = JSON.parse(localStorage.getItem('prg'));
            if (programInfo) {
                // reget session if in a program
                await AuthService.getSession();
                const user = AuthService.getCurrentUser();
                setCurrentUser({ ...user, jobName: user?.job?.name });
                setCanFetchData(true);
                await refreshUserPreferences();
            } else {
                await AuthService.getSession();
                const user = AuthService.getCurrentUser();
                setCurrentUser({ ...user });
            }
        })();
    }, []);

    return (
        <UserContext.Provider
            value={{
                userPreferences,
                updateUserPreferences,
                refreshUserPreferences,
                currentUser,
                updatingData,
                updatedUserId,
                setUpdatingData,
                teamList,
                updatedTeam,
                canFetchData,
                deconnexionTime,
                setDeconnexionTime,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
