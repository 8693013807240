import { configureStore } from '@reduxjs/toolkit';
import shapesReducer from './slices/shape.slice'
import canvasReducer from './slices/canvas.slice'

/* eslint-disable-next-line */
export const store = configureStore({
  reducer: {
    shapes: shapesReducer,
    canvas: canvasReducer
  },
});